import Particles from "react-tsparticles";
import { Container } from "./styles";

import img1 from "../../assets/Rectangle 20 (1).png";
import img2 from "../../assets/images/image 1 (1).png";
import img3 from "../../assets/images/image 3.png";
import img4 from "../../assets/images/image 4.png";
import img5 from "../../assets/images/image 5.png";
import img6 from "../../assets/images/image 6.png";
import img7 from "../../assets/images/image 7.png";
import img8 from "../../assets/images/image 8.png";
import img9 from "../../assets/images/image 9.png";
import img10 from "../../assets/images/Rectangle 20 (2).png";
import img11 from "../../assets/images/Rectangle 20.png";
import img12 from "../../assets/images/Rectangle 6774.png";
import img13 from "../../assets/images/Rectangle 6775.png";
import img14 from "../../assets/images/Rectangle 6776.png";
import img15 from "../../assets/images/Rectangle 6777.png";

import Home from "../../pages/home/Home";
import { FC } from "react";

export const Main: FC = () => {
  return (
    <div className="hero">
      <Container>
        <Particles
          id="tsparticles"
          options={{
            fullScreen: {
              enable: true,
              zIndex: 1,
            },
            detectRetina: true,
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: false,
                  mode: "push",
                },
                onDiv: {
                  elementId: "repulse-div",
                  enable: false,
                  mode: "repulse",
                },
                onHover: {
                  enable: false,
                  mode: "bubble",
                  parallax: {
                    enable: false,
                    force: 60,
                    smooth: 10,
                  },
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 2,
                },
                connect: {
                  distance: 80,
                  lineLinked: {
                    opacity: 0.5,
                  },
                  radius: 60,
                },
                grab: {
                  distance: 400,
                  lineLinked: {
                    opacity: 1,
                  },
                },
                // push: {
                //   quantity: 2,
                // },
                remove: {
                  quantity: 2,
                },
                // repulse: {
                //   distance: 200,
                //   duration: 0.4,
                // },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },

              lineLinked: {
                blink: false,
                color: "#000",
                consent: false,
                distance: 150,
                enable: false,
                opacity: 0.4,
                width: 1,
              },
              move: {
                attract: {
                  enable: false,
                  rotate: {
                    x: 600,
                    y: 1200,
                  },
                },
                bounce: false,
                direction: "none",
                enable: true,
                outMode: "out",
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                limit: 15,
              },
              opacity: {
                animation: {
                  enable: true,
                  minimumValue: 0.2,
                  speed: 1,
                  sync: false,
                },
                random: true,
                value: 1,
              },
              rotate: {
                animation: {
                  enable: false,
                  speed: 5,
                  sync: false,
                },
                direction: "random",
                random: true,
                value: 0,
              },
              shape: {
                character: {
                  fill: false,
                  font: "Verdana",
                  style: "",
                  value: "*",
                  // weight: "400",
                },
                image: [
                  {
                    src: img1,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img2,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img3,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img4,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img5,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img6,
                    width: 100,
                    height: 100,
                  },

                  {
                    src: img7,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img8,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img9,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img10,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img11,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img12,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img13,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img14,
                    width: 100,
                    height: 100,
                  },
                  {
                    src: img15,
                    width: 100,
                    height: 100,
                  },
                ],
                polygon: {
                  sides: 5,
                },
                stroke: {
                  color: "#000000",
                  width: 0,
                },
                type: "image",
              },
              size: {
                animation: {
                  enable: false,
                  minimumValue: 0.2,
                  speed: 40,
                  sync: false,
                },
                random: false,
                value: 45,
              },
            },
            polygon: {
              draw: {
                enable: false,
                lineColor: "#ffffff",
                lineWidth: 0.5,
              },
              move: {
                radius: 10,
              },
              scale: 1,
              url: "",
            },
          }}
        />

        <Home />
      </Container>
    </div>
  );
};
