import { Header } from "../../components/Header/Header";
import { BtnPrimary } from "../../components/Button/Btn";
import { Footer } from "../../components/Footer/Footer";
import { FC, useState } from "react";
import { PopUpLayout } from "../../components/PopUp/PopUpLayout";

const Home: FC = () => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Header openModal={openModal} />
      <div className="flex items-center justify-center h-screen  ">
        <div className="text-center text_main md:text-[61px] text-[32px] tracking-widest md:tracking-normal ">
          <h1>
            {" "}
            <span>Echoes of Ancient Bharat</span>
            <br /> First CIvilization first connection
          </h1>
          <BtnPrimary
            className=" INDEX-Z cursor-pointer text-[#FF9914] bg-white   w-[220px]  lg:w-[270px] md:h-[96px] mt-24 z-50"
            onClick={() => {
              openModal();
            }}
          >
            Download and Discover
          </BtnPrimary>

          <PopUpLayout
            closeModal={closeModal}
            openModal={openModal}
            isOpen={isOpen}
          ></PopUpLayout>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
