import { Container } from "./styles";

import { useState } from "react";

import { BtnSecondary } from "../Button/Btn";
import { Link, useLocation, useNavigate } from "react-router-dom";
type Headerprops = {
  openModal: () => void;
  className?: string;
};

export function Header({ openModal, className }: Headerprops) {
  const [isActive, setActive] = useState(false);
  const naviate = useNavigate();
  const { pathname } = useLocation();

  function toggleTheme() {
    let html = document.getElementsByTagName("html")[0];
    html.classList.toggle("light");
  }

  const navigator = (path: string) => {
    setActive(false);
    naviate(path);
    // console.log("click", path);
  };

  return (
    <Container className={` ${className} header-fixed !bg-inherit`}>
      <div className="logo">
        <Link to={"/"} title="logo">
          <svg
            className="md:w-[96px] w-[72px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 96 96"
            fill="none"
          >
            <g clip-path="url(#clip0_91_104)">
              <path
                d="M76.8896 0H19.1104C8.55602 0 0 8.55602 0 19.1104V76.8896C0 87.444 8.55602 96 19.1104 96H76.8896C87.444 96 96 87.444 96 76.8896V19.1104C96 8.55602 87.444 0 76.8896 0Z"
                fill="white"
              />
              <path
                d="M41.2032 74.7518V36.531H25.4592V21.2478H53.7856V74.7518H41.2032Z"
                fill="url(#paint0_linear_91_104)"
              />
              <path
                d="M70.5408 36.7871H56.9344V33.6383H67.3921V30.6047H56.9344V21.2351H70.5408V24.3839H60.0832V27.4559H70.5408V36.7871Z"
                fill="url(#paint1_linear_91_104)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_91_104"
                x1="39.6224"
                y1="21.2478"
                x2="39.6224"
                y2="74.7518"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF9413" />
                <stop offset="1" stop-color="#FF6B0B" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_91_104"
                x1="63.7376"
                y1="21.2351"
                x2="63.7376"
                y2="36.7871"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF9413" />
                <stop offset="1" stop-color="#FF6B0B" />
              </linearGradient>
              <clipPath id="clip0_91_104">
                <rect width="96" height="96" rx="48" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>

      <input
        onChange={toggleTheme}
        className="container_toggle"
        type="checkbox"
        id="switch"
        name="mode"
      />

      <nav className={isActive ? "active" : ""}>
        <div className="md:space-x-12 md:space-y-0 flex flex-col md:flex-row text-center justify-center items-center space-y-5">
          {/* <button
            onClick={() => navigator("/")}
            className="text-[#242527] text-[24px] Poppins-font "
          >
            Blogs
          </button>

          <button
            onClick={() => navigator("/support")}
            className="text-[#242527] text-[24px] Poppins-font"
          >
            Help & Support
          </button> */}
        </div>
        <div className=" flex flex-col md:hidden !text-center justify-center !items-center space-y-5 ">
          <button
            onClick={() => navigator("/privacy")}
            className="text-[#242527] text-[24px] Poppins-font "
          >
            Privacy
          </button>

          <button
            onClick={() => navigator("/terms")}
            className="text-[#242527] text-[24px] Poppins-font"
          >
            Terms
          </button>

          {/* <button
            onClick={() => navigator("/faqs")}
            className="text-[#242527] text-[24px] Poppins-font"
          >
            FAQs
          </button> */}
        </div>

        <BtnSecondary
          onClick={() => {
            openModal();
          }}
          className=" w-[257px] text-[24px] h-[96px] ml-8 hidden md:flex text-center justify-center text-whitePoppins-font "
        >
          {" "}
          {pathname === "/" || pathname === "/home"
            ? "Download now"
            : "Get The Tej"}
        </BtnSecondary>
      </nav>

      <div
        aria-expanded={isActive ? "true" : "false"}
        aria-haspopup="true"
        aria-label={isActive ? "Fechar menu" : "Abrir menu"}
        className={isActive ? "menu active" : "menu"}
        onClick={() => {
          setActive(!isActive);
        }}
      ></div>
    </Container>
  );
}
